
import { defineComponent, computed } from 'vue'
import Image from 'primevue/image'
import Button from 'primevue/button'
import { useRouter, useRoute } from 'vue-router'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    Image,
    Button
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const redirectHome = () => { router.push({ name: JobBankRouteNames.COMPANIES }) }
    const currentRoute = computed(() => {
      return route.name
    })
    return {
      redirectHome,
      currentRoute
    }
  }
})
